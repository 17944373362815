import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}`

export default {
  cashbackApi() {
    return {
      getCashback: async () => {
        const endpoint = `${URL}/cashback-wallet`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCashbackWalletHistory: async (params) => {
        const endpoint = `${URL}/cashback-wallet-history`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      redeemCashback: async (amount) => {
        const endpoint = `${URL}/cashback/convert`
        try {
          const res = await axios.post(
            endpoint,
            { amount },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
