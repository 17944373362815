<template>
  <v-container class="mb-10">
    <h1>Rewards</h1>
    <p class="text-subtitle-1 font-weight-bold">
      <!--      Got questions? Call or Whatsapp {{ siteSettings.phone }}-->
    </p>
    <v-overlay opacity="0.8" :value="gettingWallet">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <div>
      <p>Help us spread the word</p>

      <p>
        SnappyExchange's customer loyalty program rewards you with cashback
        points for every transaction you make. You can redeem these points for
        cash!
      </p>
    </div>

    <v-row>
      <v-col md="12" cols="12">
        <v-card elevation="3" color="blue" rounded="lg" class="pa-2">
          <v-card-text class="white--text">
            <div class="grid grid-cols-3 gap-x-3">
              <v-card class="opacity-75" elevation="0">
                <div class="p-2">
                  <p class="mb-0 text-sm text-gray-500">Points Earned</p>
                  <p class="mb-0 text-lg font-semibold text-gray-700">
                    {{ cashback.totalEarned }}
                  </p>
                </div>
              </v-card>
              <v-card class="opacity-75" elevation="0">
                <div class="p-2">
                  <p class="mb-0 text-sm text-gray-500">Points Redeemed</p>
                  <p class="mb-0 text-lg font-semibold text-gray-700">
                    {{ cashback.totalRedeemed }}
                  </p>
                </div>
              </v-card>
              <v-card class="opacity-75" elevation="0">
                <div class="p-2">
                  <p class="mb-0 text-sm text-gray-500">Points Available</p>
                  <p class="mb-0 text-lg font-semibold text-gray-700">
                    {{ cashback.totalEarned - cashback.totalRedeemed }}
                  </p>
                </div>
              </v-card>
            </div>
            <p class="overline white--text opacity-80 mb-0">Cashback Points</p>
            <p class="overline white--text opacity-70 mb-0">
              1 Cashback Points =
              {{ getCurrencySymbol(userCountry) }} {{ getToken }}
            </p>
            <span class="opacity-85">Available cashback points</span>
            <h4 class="text-h4">{{ cashback.balance }}</h4>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="d-block d-sm-flex">
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-btn
              depressed
              @click.stop="cashbackDialog = true"
              class="mt-2 mx-2 text-center"
            >
              Redeem
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-dialog max-width="500" v-model="cashbackDialog">
        <v-form ref="convertForm" @submit.prevent="validate('cashback')">
          <v-card>
            <v-container>
              <v-card-title>Redeem Cashback</v-card-title>
              <p class="overline opacity-70 mb-0 text-center">
                1 Naira = {{ getToken }} Points
              </p>
              <v-card-text>
                <p>
                  Your currently have
                  <b>{{ Number(cashback.balance).toFixed(2) }} Points</b> in
                  your cashback wallet
                </p>
                <p>
                  Please note that the minimum cashback points you can convert
                  to your wallet is
                  <b> {{ getMinimumToken }}</b
                  >. Ensure you have earned up to that before you proceed
                </p>
                <v-text-field
                  filled
                  type="number"
                  label="Amount to Redeem"
                  :rules="[
                    (v) =>
                      (v && v <= cashback.balance) ||
                      'You can\'t convert your present points'
                  ]"
                  v-model="cashbackAmount"
                ></v-text-field>
                <v-btn
                  :loading="loadingConvertCashback"
                  :disabled="cashbackAmount <= 0"
                  type="submit"
                  color="primary"
                  large
                  block
                  >REDEEM TO WALLET
                </v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
// import noTransaction from '../../../components/noTransaction.vue'
import walletApi from '../../api/users/wallet'
import { mapState } from 'vuex'
import { numberWithCommas } from '../../utils/helpers'
import {
  COUNTRIES,
  getCurrencyCode,
  getCurrencySymbol
} from '../../utils/countryHelpers'
import cashback from '../../api/users/cashback'

export default {
  data: () => ({
    COUNTRIES,
    getCurrencyCode,
    getCurrencySymbol,
    numberWithCommas,
    cashbackDialog: false,
    convertDialog: false,
    banks: 0,
    loadingConvert: false,
    gettingWallet: false,
    amount: '',
    loadingConvertCashback: false,
    cashbackAmount: ''
  }),
  components: {
    // noTransaction
  },
  computed: {
    ...mapState({
      wallet: (state) => state.user.wallet,
      cashback: (state) => state.user.cashback,
      user: (state) => state.user.user,
      siteSettings: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    }),
    getMinimumToken() {
      return this.siteSettings.minimumCashbackPoint
    },
    getToken() {
      const v =
        Number(this.siteSettings.cashbackNairaConversionRate) /
        this.countrySetting?.[this.userCountry?.toLowerCase()]?.nairaRate
      return v.toFixed(2)
    }
  },
  created() {
    this.$store.dispatch('user/getSiteSettings')
    this.$store.dispatch('user/getCountryCurrency')
    this.getWalletData()
    this.getCashback()
  },
  methods: {
    getCurrencySymbol,
    copy(copy) {
      const input = document.createElement('input')
      input.value = copy

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$store.dispatch('alert', { message: 'Copied!', time: 2000 })
    },
    validate(type) {
      if (this.$refs.convertForm.validate()) {
        this.convertCashback()
      }
    },
    async convertCashback() {
      this.loadingConvertCashback = true

      const amount = this.cashbackAmount
      const res = await cashback.cashbackApi().redeemCashback(amount)

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingConvert = false
        this.loadingConvertCashback = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Convert successful',
        status: true
      })
      this.getWalletData()
      this.getCashback()
      this.amount = ''
      this.cashbackAmount = ''
      this.loadingConvert = false
      this.convertDialog = false
      this.loadingConvertCashback = true
      this.cashbackDialog = false
    },
    async getWalletData() {
      this.gettingWallet = true
      const res = await walletApi.data().getWallet()
      this.$store.commit('user/setWalletData', res.data.data)
      this.gettingWallet = false
    },
    async getCashback() {
      const res = await cashback.cashbackApi().getCashback()
      this.$store.commit('user/setCashback', res.data.data)
    }
  }
}
</script>

<style></style>
